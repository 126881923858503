/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import {
  ShClientACHDTO,
  ShClientInvoiceDTO,
  ShCreditCard,
} from '@shoootin/api';
import { ShColors, ShFonts } from '@shoootin/design-tokens';
import { ShMoment } from '@shoootin/utils';
import { ShText } from '@shoootin/translations';

import { ShCol, ShRow } from '../../grid/ShGrid';

import { ShPrice } from '../../components/price/ShPrice';
import { ShLinkText } from '../../designSystem/primitives/text/ShLinkText/ShLinkText';
import { ShTitle } from '../../designSystem/primitives/ShTitle/ShTitle';
import { ShOrderStepPayment } from '../../components/paymentMethod/ShPaymentMethodTranslations';
import { ShSavedCreditCard } from '../../components/paymentMethod/ShSavedCreditCard';
import { ShCreditCardInput } from '../../components/paymentMethod/ShCreditCardInput';

import { ShButtonAsync } from '../../designSystem/primitives/button/ShButton/ShButtonAsync';
import { LogoSvg as ShLogoSvg } from '../../components/svg/LogoSvg';
import { ShCurrency } from '@shoootin/config';
import { ShSavedACH } from '../../components/paymentMethod/ShSavedACH';
import { ShClientInvoiceBillingEntity } from '@shoootin/api';

export const ShInvoiceHeaderPartial = ({
  shoootinBillingEntity,
  clientBillingEntity,
  onEdit,
}: {
  shoootinBillingEntity: ShClientInvoiceBillingEntity;
  clientBillingEntity: ShClientInvoiceBillingEntity;
  onEdit?: () => void; // only for admin
}) => {
  return (
    <div>
      <div
        css={{ display: 'flex', justifyContent: 'center', paddingBottom: 60 }}
      >
        <ShLogoSvg width={150} />
      </div>
      <div css={{ paddingBottom: 30 }}>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <div css={{ fontFamily: ShFonts.secondary, fontSize: '120%' }}>
              {shoootinBillingEntity.company}
            </div>
            <div>
              {shoootinBillingEntity.address.map((s) => (
                <div key={s}>{s}</div>
              ))}
            </div>
            <div>{shoootinBillingEntity.taxNumber}</div>
          </div>
          <div css={{ textAlign: 'right' }}>
            <div css={{ fontFamily: ShFonts.secondary, fontSize: '120%' }}>
              {clientBillingEntity.company}
            </div>
            <div css={{ fontFamily: ShFonts.secondary }}>
              {clientBillingEntity.department}
            </div>
            <div>
              {clientBillingEntity.address.map((s) => (
                <div key={s}>{s}</div>
              ))}
            </div>
            <div>{clientBillingEntity.taxNumber}</div>
            {onEdit && (
              <div css={{ marginTop: 10 }}>
                <a onClick={onEdit}>
                  <small>
                    <ShLinkText>Edit BE</ShLinkText>
                  </small>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShInvoiceHeader = ({
  invoice,
  onEdit,
}: {
  invoice: ShClientInvoiceDTO;
  onEdit?: () => void; // only for admin
}) => {
  return (
    <div css={{ paddingBottom: 30 }}>
      <ShInvoiceHeaderPartial
        clientBillingEntity={invoice.clientBillingEntity}
        shoootinBillingEntity={invoice.shootingBillingEntity}
        onEdit={onEdit}
      />
      <div>
        <div
          css={{
            paddingBottom: 15,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div
              css={{
                fontFamily: ShFonts.secondary,
                //paddingBottom: 5,
                fontSize: '120%',
              }}
            >
              {invoice.typeOfInvoice} {invoice.invoiceNb}
            </div>
            <div>{ShMoment(invoice.date).format('LL')}</div>

            <div>
              {invoice.paymentState.map((state: string, index) => (
                <span
                  key={`${index}-${state}`}
                  css={{
                    marginRight: 5,
                    color: invoice.paid ? ShColors.black : ShColors.error,
                    fontFamily: invoice.paid
                      ? ShFonts.primary
                      : ShFonts.secondary,
                  }}
                >
                  {state}
                </span>
              ))}
            </div>
          </div>
          {!invoice.paid && (
            <div css={{ textAlign: 'right' }}>
              <div>
                <b>
                  <ShText message={'client_billingEntity_paymentDelay'} />
                  {' : '}
                  {invoice.clientBillingEntity.paymentDelay}{' '}
                  <ShText message={'client_billingEntity_paymentDelayDays'} />
                </b>
              </div>
              <div>
                <b>
                  <ShText message={'common_invoices_dueDate'} />
                  {' : '}
                  {ShMoment(invoice.dueDate).format('LL')}
                </b>
              </div>
            </div>
          )}
        </div>
        <div css={{ fontSize: '90%' }}>
          {invoice.extraInfos.map((s) => (
            <div key={s}>{s}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const ShInvoiceElementComponent = ({
  currency,
  description,
  shootingAddress,
  extraFields,
  date,
  user,
  totalWithoutTax,
  totalWithTax,
}: {
  currency: ShCurrency;
  description: string;
  shootingAddress?: string;
  extraFields: string[];
  date: Date;
  user?: string;
  totalWithoutTax: number;
  totalWithTax: number;
}) => {
  return (
    <ShRow
      css={{
        paddingBottom: 10,
        marginBottom: 10,
        borderBottom: `1px solid ${ShColors.pattern}`,
        alignItems: 'center',
      }}
    >
      <ShCol xs={40}>
        <div>
          <b>{description}</b>
        </div>
        {!!shootingAddress && <div>{shootingAddress}</div>}
        <div css={{ fontSize: '90%' }}>
          {extraFields.map((info: string, index: number) => (
            <div key={index}>{info}</div>
          ))}
        </div>
        <div css={{ fontSize: '90%' }}>
          {ShMoment(date).format('LL')}
          {user && <span> - {user}</span>}
        </div>
      </ShCol>
      <ShCol xs={10} css={{ textAlign: 'right' }}>
        <ShPrice price={totalWithoutTax} currency={currency} />
      </ShCol>
      <ShCol xs={10} css={{ textAlign: 'right' }}>
        <ShPrice price={totalWithTax} currency={currency} />
      </ShCol>
    </ShRow>
  );
};

export const ShInvoiceElements = ({
  invoice,
  payable,
  collapsible = false,
}: {
  invoice: ShClientInvoiceDTO;
  payable: boolean;
  collapsible: boolean;
}) => {
  const elementsLength = invoice.elements.length;
  const [hideElements, setHideElements] = useState<boolean>(
    !collapsible && elementsLength > 3 && payable,
  );

  const elementsToShow = hideElements
    ? invoice.elements.slice(0, 3)
    : invoice.elements;

  return (
    <div css={{ paddingBottom: 30 }}>
      <ShRow
        css={{
          paddingBottom: 10,
          marginBottom: 15,
          fontSize: '105%',
          fontFamily: ShFonts.secondary,
          borderBottom: `1px solid ${ShColors.pattern}`,
        }}
      >
        <ShCol xs={40}>
          <ShText message={'common_invoices_rowDescription'} />
        </ShCol>
        <ShCol xs={10} css={{ textAlign: 'right' }}>
          <ShText message={'common_invoices_priceWithoutTax'} />
        </ShCol>
        <ShCol xs={10} css={{ textAlign: 'right' }}>
          <ShText message={'common_invoices_priceWithTax'} />
        </ShCol>
      </ShRow>
      {elementsToShow.map((element, index) => (
        <ShInvoiceElementComponent
          key={index}
          currency={invoice.currency}
          date={element.date}
          description={element.description}
          extraFields={element.extraFields}
          shootingAddress={element.shootingAddress}
          totalWithTax={element.totalWithTax}
          totalWithoutTax={element.totalWithoutTax}
          user={element.user}
        />
      ))}
      {!hideElements && invoice.lateExtraCharge !== 0 && (
        <ShRow
          css={{
            paddingBottom: 10,
            marginBottom: 10,
            borderBottom: `1px solid ${ShColors.pattern}`,
            alignItems: 'center',
          }}
        >
          <ShCol xs={40}>
            <div>
              <b>
                <ShText message={'common_invoices_lateExtraCharge'} />
              </b>
            </div>
          </ShCol>
          <ShCol xs={10} css={{ textAlign: 'right' }}>
            <ShPrice
              price={invoice.lateExtraCharge}
              currency={invoice.currency}
            />
          </ShCol>
          <ShCol xs={10} css={{ textAlign: 'right' }}>
            <ShPrice
              price={invoice.lateExtraCharge}
              currency={invoice.currency}
            />
          </ShCol>
        </ShRow>
      )}
      {!hideElements && invoice.lateExtraChargeRate !== 0 && (
        <ShRow
          css={{
            paddingBottom: 10,
            marginBottom: 10,
            borderBottom: `1px solid ${ShColors.pattern}`,
            alignItems: 'center',
          }}
        >
          <ShCol xs={40}>
            <div>
              <b>
                <ShText message={'common_invoices_lateExtraChargeRate'} />
              </b>
            </div>
          </ShCol>
          <ShCol xs={10} css={{ textAlign: 'right' }}>
            <ShPrice
              price={invoice.lateExtraChargeRate}
              currency={invoice.currency}
            />
          </ShCol>
          <ShCol xs={10} css={{ textAlign: 'right' }}>
            <ShPrice
              price={invoice.lateExtraChargeRate}
              currency={invoice.currency}
            />
          </ShCol>
        </ShRow>
      )}
      {hideElements && (
        <div css={{ textAlign: 'center', marginTop: 20 }}>
          <div onClick={() => setHideElements(false)}>
            <ShLinkText>
              <ShText message={'common_invoices_seeMore'} />
            </ShLinkText>
          </div>
        </div>
      )}
    </div>
  );
};

export const ShInvoiceTotals = ({
  totalWithoutTax,
  totalTax,
  totalWithTax,
  currency,
}: {
  totalWithoutTax: number;
  totalTax: number;
  totalWithTax: number;
  currency: ShCurrency;
}) => {
  return (
    <div css={{ textAlign: 'right', paddingBottom: 50 }}>
      <ShRow>
        <ShCol xs={30}>
          <div css={{ paddingBottom: 5 }}>
            <ShText message={'common_order_totalPriceWithoutTax'} />
          </div>
          <div css={{ fontFamily: ShFonts.secondary, fontSize: '120%' }}>
            <ShPrice price={totalWithoutTax} currency={currency} />
          </div>
        </ShCol>
        <ShCol xs={15}>
          <div css={{ paddingBottom: 5 }}>
            <ShText message={'common_order_tax'} />
          </div>
          <div css={{ fontFamily: ShFonts.secondary, fontSize: '120%' }}>
            <ShPrice price={totalTax} currency={currency} />
          </div>
        </ShCol>
        <ShCol xs={15}>
          <div css={{ paddingBottom: 5 }}>
            <ShText message={'common_order_totalPriceWithTax'} />
          </div>
          <div css={{ fontFamily: ShFonts.secondary, fontSize: '120%' }}>
            <ShPrice price={totalWithTax} currency={currency} />
          </div>
        </ShCol>
      </ShRow>
    </div>
  );
};

export const ShInvoicePaymentInformations = ({
  accountInformations,
  invoiceNb,
}: {
  accountInformations: string[];
  invoiceNb: string;
}) => {
  return (
    <div
      css={{
        paddingTop: 20,
        marginTop: 20,
        borderTop: `1px solid ${ShColors.pattern}`,
      }}
    >
      <div css={{ fontSize: '110%' }}>
        <b>
          <ShText message={'common_invoices_paymentInformationsTitle'} />
        </b>
      </div>
      <div>
        <ShText message={'common_invoices_paymentInformationsDescription'} />
      </div>
      <div
        css={{
          border: `1px solid ${ShColors.black}`,
          padding: 15,
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        <div>
          {accountInformations.map((s) => (
            <b key={s}>
              <div>{s}</div>
            </b>
          ))}
        </div>
        <div>
          <ShText
            message={'common_invoices_paymentInformationsInvoice'}
            values={{ id: <b>{invoiceNb}</b> }}
          />
        </div>
      </div>
      <div>
        <small>
          <ShText message={'common_invoices_paymentInformationsFooter'} />
        </small>
      </div>
    </div>
  );
};

export const ShInvoiceFooter = ({
  footerInformations,
}: {
  footerInformations: string;
}) => {
  return (
    <div
      css={{
        fontSize: '70%',
        textAlign: 'center',
        paddingTop: 80,
      }}
    >
      {footerInformations.split('<br>').map((s) => (
        <div key={s}>{s}</div>
      ))}
    </div>
  );
};

export const ShInvoicePayment = ({
  cards,
  achList,
  onValidateClick,
  selectedPaymentMethodId,
  deleteCreditCard,
  paymentErrors,
  setPaymentError,
  saveCreditCard,
  setSaveCreditCard,
  selectCreditCard,
  selectACH,
  isDev,
  canSaveCreditCard = true,
}: {
  cards: ShCreditCard[];
  achList: ShClientACHDTO[];
  onValidateClick: () => void;
  selectedPaymentMethodId?: string;
  deleteCreditCard: () => void;
  paymentErrors?: string;
  setPaymentError: (error?: string) => void;
  saveCreditCard: boolean;
  setSaveCreditCard?: (save: boolean) => void;
  selectCreditCard?: (cardId: string) => void;
  selectACH?: (achId: string) => void;
  isDev: boolean;
  canSaveCreditCard?: boolean;
}) => {
  return (
    <div
      css={{
        marginTop: 20,
        padding: 20,
        borderTop: `1px solid ${ShColors.pattern}`,
      }}
    >
      <div>
        <ShTitle size={3} css={{ marginBottom: 20 }}>
          <ShText message={ShOrderStepPayment.paymentMethodTitle} />
        </ShTitle>
        {cards.length > 0 && (
          <ShPaymentBySavedCard
            cards={cards}
            selectCreditCard={selectCreditCard}
            selectedPaymentMethodId={selectedPaymentMethodId}
          />
        )}
        {achList.length > 0 && (
          <ShPaymentByACH
            achList={achList}
            selectACH={selectACH}
            selectedPaymentMethodId={selectedPaymentMethodId}
          />
        )}
        <ShPaymentByNewCard
          selectedPaymentMethodId={selectedPaymentMethodId}
          deleteCreditCard={deleteCreditCard}
          paymentErrors={paymentErrors}
          setPaymentError={setPaymentError}
          saveCreditCard={saveCreditCard}
          setSaveCreditCard={setSaveCreditCard}
          isDev={isDev}
          canSaveCreditCard={canSaveCreditCard}
        />

        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: 20,
          }}
        >
          <ShButtonAsync size={'l'} onClick={onValidateClick}>
            <ShText message={'common_actions_validate'} />
          </ShButtonAsync>
        </div>
      </div>
    </div>
  );
};

export const ShPaymentByACH = ({
  achList,
  selectedPaymentMethodId,
  selectACH,
}: {
  achList: ShClientACHDTO[];
  selectedPaymentMethodId?: string;
  selectACH?: (achId: string) => void;
}) => {
  return (
    <div
      css={{
        borderTop: `1px solid ${ShColors.pattern}`,
        marginTop: 40,
        paddingTop: 20,
      }}
    >
      <div css={{ paddingBottom: 20 }}>
        <ShTitle size={4}>
          <ShText message={ShOrderStepPayment.paymentMethodSavedACHTitle} />
        </ShTitle>
      </div>
      <div>
        {achList.map((ach, index) => (
          <div key={ach.id} css={index > 0 ? { marginTop: 20 } : {}}>
            <ShSavedACH
              ach={ach}
              selected={
                !!selectedPaymentMethodId && selectedPaymentMethodId === ach.id
              }
              onSelect={() => selectACH && selectACH(ach.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const ShPaymentBySavedCard = ({
  cards,
  selectedPaymentMethodId,
  selectCreditCard,
}: {
  cards: ShCreditCard[];
  selectedPaymentMethodId?: string;
  selectCreditCard?: (cardId: string) => void;
}) => {
  return (
    <div
      css={{
        borderTop: `1px solid ${ShColors.pattern}`,
        marginTop: 40,
        paddingTop: 20,
      }}
    >
      <div css={{ paddingBottom: 20 }}>
        <ShTitle size={4}>
          <ShText
            message={ShOrderStepPayment.paymentMethodSavedCreditCardTitle}
          />
        </ShTitle>
      </div>
      <div>
        {cards.map((card, index) => (
          <div key={card.id} css={index > 0 ? { marginTop: 20 } : {}}>
            <ShSavedCreditCard
              selected={
                !!selectedPaymentMethodId && selectedPaymentMethodId === card.id
              }
              onSelect={() => selectCreditCard && selectCreditCard(card.id)}
              card={card}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const ShPaymentByNewCard = ({
  selectedPaymentMethodId,
  deleteCreditCard,
  paymentErrors,
  setPaymentError,
  saveCreditCard,
  setSaveCreditCard,
  isDev,
  canSaveCreditCard = true,
}: {
  selectedPaymentMethodId?: string;
  deleteCreditCard: () => void;
  paymentErrors?: string;
  setPaymentError: (error?: string) => void;
  saveCreditCard: boolean;
  setSaveCreditCard?: (save: boolean) => void;
  isDev: boolean;
  canSaveCreditCard?: boolean;
}) => {
  // Hacky way to "reset" the stripe input when an existing credit card is selected
  const [key, setKey] = useState(0);
  useEffect(() => {
    if (selectedPaymentMethodId) {
      setKey((k) => k + 1);
    }
    setPaymentError(undefined);
  }, [selectedPaymentMethodId]);

  return (
    <div
      css={{
        borderTop: `1px solid ${ShColors.pattern}`,
        marginTop: 40,
        paddingTop: 20,
      }}
    >
      <div css={{ paddingBottom: 20 }}>
        <ShTitle size={4}>
          <ShText message={'enums_PaymentMethods_title_CREDIT_CARD'} />
        </ShTitle>
      </div>
      <ShCreditCardInput
        key={key}
        onChange={() => {
          deleteCreditCard();
          setPaymentError(undefined);
        }}
        setSaveCreditCard={setSaveCreditCard}
        saveCreditCard={saveCreditCard}
        paymentErrors={paymentErrors}
        isDev={isDev}
        canSaveCreditCard={canSaveCreditCard}
      />
    </div>
  );
};
