import React, { useState } from 'react';
import {
  ShBillingInvoiceAPI,
  ShClientInvoiceDTO,
  ShFrontBillingInvoiceAPI,
} from '@shoootin/api';
import { ReactStripeElements } from 'react-stripe-elements';
import {ShSweetAlert} from '@shoootin/components-web';

type ValidatePaymentPayloadWithNewCreditCard = {
  paymentMethodType: 'card';
  paymentMethodId?: string;
  paymentIntentId?: string;
  stripeElements?: ReactStripeElements.StripeProps;
  saveCreditCard?: boolean;
  payWithNewCreditCard: true;
};
type ValidatePaymentPayloadWithSavedCreditCard = {
  paymentMethodType: 'card';
  paymentMethodId?: string;
  paymentIntentId?: string;
  stripeElements?: ReactStripeElements.StripeProps;
  saveCreditCard?: boolean;
  payWithNewCreditCard: false;
};

type ValidatePaymentPayloadWithACH = {
  paymentMethodType: 'us_bank_account';
  paymentMethodId?: string;
  paymentIntentId: undefined;
  payWithNewCreditCard: false;
};

type ValidatePaymentPayload =
  | ValidatePaymentPayloadWithNewCreditCard
  | ValidatePaymentPayloadWithSavedCreditCard
  | ValidatePaymentPayloadWithACH;

type InvoicePaymentAPI = {
  selectCreditCard: (selectedCreditCard: string) => void;
  selectACH: (selectedACH: string) => void;
  deleteCreditCard: () => void;
  setPaymentErrors: (paymentErrors?: string) => void;
  saveCreditCard: (savedCreditCard: boolean) => void;
  validateInvoicePayment: (payload: ValidatePaymentPayload) => void;
  validateFrontInvoicePayment: (payload: ValidatePaymentPayload) => void;
};

export type InvoicePayment = {
  invoicePaymentState: InvoicePaymentState;
  invoicePaymentAPI: InvoicePaymentAPI;
};

type PaymentMethodType = 'card' | 'us_bank_account';

type InvoicePaymentState = {
  paymentMethodType: PaymentMethodType;
  selectedPaymentMethodId?: string;
  savedCreditCard: boolean;
  paymentErrors?: string;
};

export const useShInvoicePayment = (
  invoice: ShClientInvoiceDTO,
  refresh?: () => void,
): InvoicePayment => {
  const [invoicePaymentState, setInvoicePaymentState] = useState<
    InvoicePaymentState
  >({
    paymentMethodType: 'card',
    selectedPaymentMethodId: undefined,
    savedCreditCard: false,
    paymentErrors: undefined,
  });

  const selectCreditCard = (selectedPaymentMethodId: string) =>
    setInvoicePaymentState((s) => ({
      ...s,
      ...{ selectedPaymentMethodId, paymentMethodType: 'card' },
    }));

  const selectACH = (selectedPaymentMethodId: string) =>
    setInvoicePaymentState((s) => ({
      ...s,
      ...{ selectedPaymentMethodId, paymentMethodType: 'us_bank_account' },
    }));

  const saveCreditCard = (savedCreditCard: boolean) =>
    setInvoicePaymentState((s) => ({ ...s, savedCreditCard }));

  const deleteCreditCard = () =>
    setInvoicePaymentState((s) => ({
      ...s,
      ...{ selectedPaymentMethodId: undefined, paymentMethodType: 'card' },
    }));

  const setPaymentErrors = (paymentErrors?: string) =>
    setInvoicePaymentState((s) => ({ ...s, paymentErrors }));

  const errorCallback = (result: any) => {
    setPaymentErrors(result.error);
  };

  const successCallback = async () => {
    refresh && (await refresh());
  };

  const processingCallback = async () => {
    ShSweetAlert.showSuccess({
      title: '',
      message: 'Your payment is processing, this may take a few days to complete.',
    });
    refresh && (await refresh());
  };

  const needActionCallback = async (
    clientSecret: string,
    payload: ValidatePaymentPayload,
    validateFn: (payload: ValidatePaymentPayload) => void,
  ) => {
    // add loading state
    // Note that stripe.handleCardAction may take several seconds to complete.
    // During that time, you should disable your form from being resubmitted
    // and show a waiting indicator like a spinner. If you receive an error result,
    // you should be sure to show that error to the customer, re-enable the form,
    // and hide the waiting indicator.
    const {
      error: errorAction,
      paymentIntent,
      //strange that stripe types doesn't contain handleCardAction...
      //https://stripe.com/docs/stripe-js/reference#stripe-handle-card-action
      //@ts-ignore
    } = await payload!.stripeElements.handleCardAction(clientSecret);

    if (errorAction) {
      setPaymentErrors(errorAction.message);
    } else {
      await validateFn({
        ...payload,
        paymentMethodId: undefined,
        paymentIntentId: paymentIntent!.id,
      });
    }
  };

  const validateInvoicePayment = async (payload: ValidatePaymentPayload) => {
    const doConfirm = (): Promise<any> => {
      if (
        payload.paymentMethodType === 'card' &&
        (payload.paymentMethodId || payload.paymentIntentId)
      ) {
        if (payload.payWithNewCreditCard) {
          return ShBillingInvoiceAPI.payInvoiceWithCreditCard(
            invoice.id,
            payload.paymentMethodId,
            payload.paymentIntentId,
            payload.saveCreditCard,
          );
        } else {
          return ShBillingInvoiceAPI.payInvoiceWithCreditCard(
            invoice.id,
            payload.paymentMethodId,
            payload.paymentIntentId,
            false,
          );
        }
      } else if (
        payload.paymentMethodType === 'us_bank_account' &&
        payload.paymentMethodId
      ) {
        return ShBillingInvoiceAPI.payInvoiceWithACH(
          invoice.id,
          payload.paymentMethodId,
        );
      }
      // if we don't match these cases
      throw new Error("Unexpected: can't confirm invoice payment");
    };

    const result = await doConfirm();

    console.log('result', result);

    if (result.status === 'success') {
      await successCallback();
    } else if (result.status === 'needAction') {
      await needActionCallback(
        result.clientSecret,
        payload,
        validateInvoicePayment,
      );
    } else if (result.status === 'error') {
      errorCallback(result);
    } else if (result.status === 'processing') {
      await processingCallback();
    }
  };

  const validateFrontInvoicePayment = async (
    payload: ValidatePaymentPayload,
  ) => {
    const doConfirm = (): Promise<any> => {
      if (
        payload.paymentMethodType === 'card' &&
        (payload.paymentMethodId || payload.paymentIntentId)
      ) {
        return ShFrontBillingInvoiceAPI.payInvoiceWithCreditCard(
          invoice.id,
          payload.paymentMethodId,
          payload.paymentIntentId,
        );
      } else if (
        payload.paymentMethodType === 'us_bank_account' &&
        payload.paymentMethodId
      ) {
        return ShFrontBillingInvoiceAPI.payInvoiceWithACH(
          invoice.id,
          payload.paymentMethodId,
        );
      }
      throw new Error("Unexpected: can't confirm invoice payment");
    };

    const result = await doConfirm();

    console.log('result', result);

    if (result.status === 'success') {
      await successCallback();
    } else if (result.status === 'needAction') {
      await needActionCallback(
        result.clientSecret,
        payload,
        validateFrontInvoicePayment,
      );
    } else if (result.status === 'error') {
      errorCallback(result);
    } else if (result.status === 'processing') {
      await processingCallback();
    }
  };

  const invoicePaymentAPI = {
    selectCreditCard,
    deleteCreditCard,
    selectACH,
    setPaymentErrors,
    saveCreditCard,
    validateInvoicePayment,
    validateFrontInvoicePayment,
  };

  return {
    invoicePaymentState,
    invoicePaymentAPI,
  };
};
